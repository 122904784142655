import {getSessionTokens, saveSessionTokens} from "./session_tokens";

// Expire token interceptor.
// If an API request return 401, we revalidate the session:
// 1. Revalidate session with axiosAuthInstance and refresh_token.
// 2. Save new access token.
// 3. Resend the original request.
export const expireTokenInterceptor = (axiosInstance, axiosAuthInstance) => {

    return axiosInstance.interceptors.response.use((response) => {
        return response
    }, function (error) {

        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {

            originalRequest._retry = true;

            // Load user tokens.
            let tokens = getSessionTokens();

            return axiosAuthInstance.post("/revalidate_session", {
                "refresh_token": tokens.refreshToken,
            }).then((response) => {

                if (response.data.auth?.valid_session) {

                    // Save new access_token.
                    saveSessionTokens(response.data.auth.access_token, tokens.refreshToken);

                    // Change Authorization header.
                    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${response.data.auth.access_token}`;

                    // Return originalRequest object.
                    return axiosInstance(originalRequest);
                }

            });
        }

        return Promise.reject(error);
    });
}

// Bearer Token Authorization Interceptor.
// Add Authorization header to the request with bearer token.
// Bearer token correspond to access_token.
export const bearerTokenAuthorizationInterceptor = (axiosInstance) => {

    return axiosInstance.interceptors.request.use(function (config) {

        // Load user tokens.export const saveSessionTokens = (accessToken, refreshToken) => {
        let tokens = getSessionTokens();

        // Add bearer token to header.
        config.headers.Authorization = `Bearer ${tokens.accessToken}`;

        return config;
    }, function (error) {

        return Promise.reject(error);
    });
}