import React, {useContext} from "react";

// Components.
import Page from "../../../components/Page/Page";

// Contexts.
import {UserContext} from "../../../contexts/UserContext";

function Home() {

    // Contexts.
    const userContext = useContext(UserContext);

    return (
        <Page title="Inicio">
            ¡Bienvenid@ {userContext.value.name} {userContext.value.last_name} a la página de especialistas de PreuApp!
        </Page>
    );
}

export default Home;