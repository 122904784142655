import axios from "axios";

// Utils.
import {bearerTokenAuthorizationInterceptor, expireTokenInterceptor} from "./axios_interceptors";

export const apiAuthInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST_API + "/auth",
    timeout: 60000
});

export const apiSpecialistInstance = axios.create({
    baseURL: process.env.REACT_APP_HOST_API + "/specialists",
    timeout: 60000
});

// Use interceptors.
bearerTokenAuthorizationInterceptor(apiSpecialistInstance);
expireTokenInterceptor(apiSpecialistInstance, apiAuthInstance);