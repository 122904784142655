import React, {useState} from "react";

import {BrowserRouter, Navigate, Routes, Route} from "react-router-dom";

// Components.
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

// Contexts.
import {AppContext, defaultAppContext} from "./contexts/AppContext";
import {UserContext, defaultUserContext} from "./contexts/UserContext";

// Pages.
import ChallengesList from "./pages/Dashboard/Challenges/List";
import ChallengesNew from "./pages/Dashboard/Challenges/New";
import ChallengesView from "./pages/Dashboard/Challenges/View";
import ChallengesEdit from "./pages/Dashboard/Challenges/Edit";
import Dashboard from "./pages/Dashboard/Dashboard";
import DocumentsList from "./pages/Dashboard/Documents/List";
import DocumentsNew from "./pages/Dashboard/Documents/New";
import Home from "./pages/Dashboard/Home/Home";
import MaterialApp from "./MaterialApp";
import NotFound from "./pages/NotFound/NotFound";
import Unauthorized from "./pages/Unauthorized/Unauthorized";

function App() {

    // Contexts.
    const [appContext, setAppContext] = useState(defaultAppContext);
    const [userContext, setUserContext] = useState(defaultUserContext);

    return (
        <AppContext.Provider
            value={{
                value: appContext,
                setValue: setAppContext
            }}
        >
            <UserContext.Provider
                value={{
                    value: userContext,
                    setValue: setUserContext
                }}
            >
                <BrowserRouter>
                    <Routes>
                        <Route element={<MaterialApp/>}>
                            <Route path="/" element={<PrivateRoute/>}>
                                <Route index element={<Navigate to="dashboard/home"/>}/>
                                <Route path="dashboard" element={<Dashboard/>}>
                                    <Route index element={<Navigate to="home"/>}/>
                                    <Route path="home" element={<Home/>}/>
                                    <Route path="documents">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<DocumentsList/>}/>
                                        <Route path="new" element={<DocumentsNew/>}/>
                                    </Route>
                                    <Route path="challenges">
                                        <Route index element={<Navigate to="list"/>}/>
                                        <Route path="list" element={<ChallengesList/>}/>
                                        <Route path="new" element={<ChallengesNew/>}/>
                                        <Route path="view/:challengeID" element={<ChallengesView/>}/>
                                        <Route path="edit/:challengeID" element={<ChallengesEdit/>}/>
                                    </Route>
                                    <Route path="*" element={<NotFound/>}/>
                                </Route>
                                <Route path="*" element={<NotFound/>}/>
                            </Route>
                            <Route path="unauthorized" element={<Unauthorized/>}/>
                            <Route path="*" element={<NotFound/>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </UserContext.Provider>
        </AppContext.Provider>
    );
}

export default App;