import React from "react";

// Components.
import Alternatives from "./Alternatives";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

// Material Icons.
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

// Models.
import {areaMaths, defaultQuestion} from "../../../models/challenges";

// Utils.
import {formatParagraph} from "../../../utils/strings";

/*
 * Three Props Need:
 * - props.state: state of challenges.
 * - props.setState: update state of challenges.
 * - props.themes: list of themes.
*/
function Questions(props) {

    // Add question.
    const addQuestion = (event) => {
        event.preventDefault();

        props.setState(prevState => ({
            ...prevState,
            questions: [
                ...prevState.questions,
                defaultQuestion,
            ]
        }));
    }

    // Remove question.
    const removeQuestion = (event, questionIndex) => {
        event.preventDefault();

        props.setState(prevState => ({
            ...prevState,
            questions: prevState.questions.filter((_, i) => i !== questionIndex),
        }));
    }

    // Update question.
    const updateQuestionField = (event, questionIndex, field) => {
        event.preventDefault();

        props.setState(prevState => ({
            ...prevState,
            questions: [
                ...prevState.questions.slice(0, questionIndex),
                {
                    ...prevState.questions[questionIndex],
                    ...field,
                },
                ...prevState.questions.slice(questionIndex + 1),
            ],
        }));
    }

    return (
        <>
            <Grid
                container
                direction="row"
                alignItems="flex-start"
                justifyContent="space-between"
                sx={{
                    marginBottom: 2,
                }}
            >
                <Grid
                    item
                >
                    <Typography
                        variant="h6"
                    >
                        Preguntas
                    </Typography>
                </Grid>

                <Grid
                    item
                >
                    <Button
                        variant="text"
                        startIcon={<AddIcon/>}
                        onClick={event => addQuestion(event)}
                        disabled={props.state.area === areaMaths}
                    >
                        Añadir pregunta
                    </Button>
                </Grid>
            </Grid>

            {
                props.state.questions.map((element, index) => (
                    <Paper
                        key={index}
                        sx={{
                            padding: 4,
                            marginBottom: 4,
                        }}
                        elevation={2}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            <Typography
                                sx={{
                                    marginBottom: 4,
                                }}
                            >
                                Pregunta {index + 1}
                            </Typography>

                            <Button
                                color="secondary"
                                variant="text"
                                startIcon={<DeleteIcon/>}
                                onClick={event => removeQuestion(event, index)}
                                disabled={props.state.questions.length <= 1}
                            >
                                Quitar Pregunta
                            </Button>
                        </Grid>

                        <FormControl
                            fullWidth
                            required
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            <InputLabel id="input-theme-label">Tema</InputLabel>
                            <Select
                                labelId="input-theme-label"
                                id="input-area"
                                label="Tema"
                                value={props.state.questions[index].theme}
                                onChange={event => updateQuestionField(event, index, {
                                    theme: parseInt(event.target.value),
                                })}
                            >
                                {
                                    Object.entries(props.themes).map((element, index) => (
                                        <MenuItem
                                            key={index}
                                            value={parseInt(element[0])}
                                        >
                                            {element[1]}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 3,
                            }}
                        >
                            <TextField
                                required
                                id="input-question"
                                label="Pregunta"
                                multiline
                                rows={6}
                                value={props.state.questions[index].question}
                                onChange={event => updateQuestionField(event, index, {
                                    question: event.target.value,
                                })}
                            />

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                <Grid
                                    item
                                >
                                    <Button
                                        color="secondary"
                                        variant="text"
                                        onClick={event => updateQuestionField(event, index, {
                                            question: formatParagraph(props.state.questions[index].question),
                                        })}
                                    >
                                        Limpiar formato
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 5,
                            }}
                        >
                            <TextField
                                id="input-image-url"
                                label="URL de la Imagen"
                                value={props.state.questions[index].image.url}
                                onChange={event => updateQuestionField(event, index, {
                                    "image": {
                                        "url": event.target.value,
                                    },
                                })}
                            />
                        </FormControl>

                        <Alternatives
                            state={props.state}
                            setState={props.setState}
                            questionIndex={index}
                        />

                        <FormControl
                            fullWidth
                            sx={{
                                marginTop: 4,
                                marginBottom: 3,
                            }}
                        >
                            <TextField
                                id="input-solution"
                                label="Solución Detallada"
                                multiline
                                rows={4}
                                value={props.state.questions[index].solution}
                                onChange={event => updateQuestionField(event, index, {
                                    solution: event.target.value,
                                })}
                            />

                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="flex-start"
                                sx={{
                                    marginTop: 1
                                }}
                            >
                                <Grid
                                    item
                                >
                                    <Button
                                        color="secondary"
                                        variant="text"
                                        onClick={event => updateQuestionField(event, index, {
                                            solution: formatParagraph(props.state.questions[index].solution),
                                        })}
                                    >
                                        Limpiar formato
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            <TextField
                                required
                                id="input-difficulty-initial"
                                label="Dificultad Inicial"
                                type="number"
                                InputProps={{inputProps: {min: 1, max: 5}}}
                                value={props.state.questions[index].difficulty_initial || props.state.questions[index].difficulty_initial === 0 ? props.state.questions[index].difficulty_initial : ""}
                                onChange={event => updateQuestionField(event, index, {
                                    difficulty_initial: parseInt(event.target.value),
                                })}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            <TextField
                                required
                                id="input-complexity"
                                label="Complejidad"
                                type="number"
                                InputProps={{inputProps: {min: 1, max: 5}}}
                                value={props.state.questions[index].complexity || props.state.questions[index].complexity === 0 ? props.state.questions[index].complexity : ""}
                                onChange={event => updateQuestionField(event, index, {
                                    complexity: parseInt(event.target.value),
                                })}
                            />
                        </FormControl>

                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            <TextField
                                id="input-annotation"
                                label="Anotación"
                                multiline
                                rows={4}
                                value={props.state.questions[index].annotation}
                                onChange={event => updateQuestionField(event, index, {
                                    annotation: event.target.value,
                                })}
                            />
                        </FormControl>
                    </Paper>
                ))
            }
        </>
    );
}

export default Questions;