import React, {useContext, useState} from "react";

import {useNavigate} from "react-router-dom";

import {useSnackbar} from "notistack";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// Material Icons.
import SaveIcon from "@mui/icons-material/Save";

// Components.
import Page from "../../../components/Page/Page";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Utils.
import {apiSpecialistInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function New() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputTitle, setInputTitle] = useState("");
    const [inputDescription, setInputDescription] = useState("");
    const [inputFile, setInputFile] = useState(null);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputTitle("");
        setInputDescription("");
        setInputFile(null);
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Define form data.
        const formData = new FormData();

        // Add file to form data.
        formData.append("file", inputFile);

        // File data.
        const jsonData = JSON.stringify({
            "title": inputTitle,
            "description": inputDescription,
        });

        formData.append("data", jsonData);

        apiSpecialistInstance.post(
            "/documents/new",
            formData,
        ).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            // Clean all inputs.
            cleanInputs();

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }

        });
    }

    return (
        <Page title="Subir documento">
            <Box
                component="form"
                autoComplete="off"
                onSubmit={handleSubmit}
            >
                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        required
                        id="input-title"
                        label="Título"
                        value={inputTitle}
                        onChange={(event) => setInputTitle(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    fullWidth
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <TextField
                        id="input-description"
                        label="Descripción"
                        multiline
                        rows={4}
                        value={inputDescription}
                        onChange={(event) => setInputDescription(event.target.value)}
                    />
                </FormControl>

                <FormControl
                    sx={{
                        marginBottom: 2
                    }}
                >
                    <input
                        required
                        accept=".pdf"
                        type="file"
                        onChange={event => setInputFile(event.target.files[0])}
                    />
                </FormControl>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    sx={{
                        marginTop: 1,
                    }}
                >
                    <Grid
                        item
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            startIcon={<SaveIcon/>}
                        >
                            Guardar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}

export default New;