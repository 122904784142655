import React, {useContext, useEffect, useState} from "react";

import {useNavigate, useParams} from "react-router-dom";

import {useSnackbar} from "notistack";

// Components.
import Challenge from "../../../components/Challenge/Challenge";
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

// Material Icons.
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";

// Models.
import {defaultChallenge,} from "../../../models/challenges";

// Utils.
import {apiSpecialistInstance} from "../../../utils/api";
import {capitalizeFirstLetter} from "../../../utils/strings";

function Edit() {

    // Contexts.
    const appContext = useContext(AppContext);

    // Navigate method.
    const navigate = useNavigate();

    // URL Params.
    const {challengeID} = useParams();

    // Snack.
    const {enqueueSnackbar} = useSnackbar();

    // Form inputs states.
    const [inputChallenge, setInputChallenge] = useState({
        ...defaultChallenge,
        id: "",
    });

    // Execute when change challengeID.
    // Obtains challenge data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiSpecialistInstance.get("/challenges/get?challengeID=" + challengeID).then((response) => {

            setInputChallenge(response.data.challenge ?? defaultChallenge);

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [challengeID]);

    // Clean form inputs.
    // Set form states to default values.
    const cleanInputs = () => {
        setInputChallenge(prevState => ({
            ...prevState,
            ...defaultChallenge,
        }));
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        apiSpecialistInstance.put("/challenges/update", inputChallenge).then((response) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            enqueueSnackbar("¡" + capitalizeFirstLetter(response.data.message) + "!", {
                variant: "success",
            });

            // Redirect.
            navigate("../list");

        }).catch((error) => {

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

            if (error.response?.data?.message) {

                enqueueSnackbar("¡" + capitalizeFirstLetter(error.response.data.message) + "!", {
                    variant: "error",
                });

            } else {

                enqueueSnackbar("¡Error interno!", {
                    variant: "error",
                });
            }

        });
    }

    return (
        <Page title="Editar desafío">
            {
                inputChallenge.id ?
                    <Box
                        component="form"
                        autoComplete="off"
                        onSubmit={handleSubmit}
                    >
                        <FormControl
                            fullWidth
                            sx={{
                                marginBottom: 2
                            }}
                        >
                            <TextField
                                id="input-id"
                                label="ID"
                                value={inputChallenge.id}
                                disabled
                            />
                        </FormControl>

                        <Challenge
                            state={inputChallenge}
                            setState={setInputChallenge}
                        />

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            sx={{
                                marginTop: 1,
                            }}
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="text"
                                    startIcon={<DeleteIcon/>}
                                    onClick={_ => cleanInputs()}
                                >
                                    Limpiar campos
                                </Button>
                            </Grid>

                            <Grid
                                item
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    startIcon={<SendIcon/>}
                                >
                                    Enviar Solicitud
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Desafío no encontrado 😔"
                                small
                            />
                            :
                            <></>
                    )
            }
        </Page>
    );
}

export default Edit;