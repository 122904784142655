import React, {useContext, useEffect, useState} from "react";

import {Link, useParams} from "react-router-dom";

// Components.
import Page from "../../../components/Page/Page";
import PersonalizedMessage from "../../../components/PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../../contexts/AppContext";

// Material Components.
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Material Icons.
import EditIcon from "@mui/icons-material/Edit";

// Models.
import {areaLanguage, areaToText, stateToText, typeToText} from "../../../models/challenges";

// Utils.
import {apiSpecialistInstance} from "../../../utils/api";
import {showDateTime} from "../../../utils/date";

function View() {

    // Contexts.
    const appContext = useContext(AppContext);

    // URL Params.
    const {challengeID} = useParams();

    const [challengeData, setChallengeData] = useState({});

    // Execute when change challengeID.
    // Obtains challenge data.
    useEffect(() => {

        // Set loading.
        appContext.setValue(prevState => ({
            ...prevState,
            loading: true,
        }));

        // Call API.
        apiSpecialistInstance.get("/challenges/get?challengeID=" + challengeID).then((response) => {

            setChallengeData(response.data.challenge ?? {});

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));

        }).catch((error) => {

            // TODO: handle error.

            // Disable loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: false,
            }));
        });

    }, [challengeID]);

    return (
        <Page title="Ver desafío">
            {
                challengeData.id ?
                    <>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                marginBottom: 3,
                            }}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="primary"
                                    variant="text"
                                    startIcon={<EditIcon/>}
                                    component={Link}
                                    to={"../edit/" + challengeData.id}
                                >
                                    Editar
                                </Button>
                            </Grid>
                        </Grid>

                        <List>
                            <ListItem>
                                <ListItemText
                                    primary="ID"
                                    secondary={challengeData.id}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Tipo"
                                    secondary={typeToText(challengeData.type)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Área"
                                    secondary={areaToText(challengeData.area)}
                                />
                            </ListItem>

                            {
                                challengeData.area === areaLanguage ?
                                    <ListItem>
                                        <ListItemText
                                            primary="Texto Base (Párrafo, Noticia, etc...)"
                                            secondary={challengeData.base_text}
                                        />
                                    </ListItem>
                                    : <></>
                            }

                            <ListItem>
                                <ListItemText
                                    primary="Fuente"
                                    secondary={challengeData.source}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Estado"
                                    secondary={stateToText(challengeData.state)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Última modificación"
                                    secondary={showDateTime(challengeData.last_modification_at)}
                                />
                            </ListItem>

                            <ListItem>
                                <ListItemText
                                    primary="Creado"
                                    secondary={showDateTime(challengeData.submit_at)}
                                />
                            </ListItem>
                        </List>
                    </>
                    :
                    (
                        !appContext.value.loading ?
                            <PersonalizedMessage
                                title="Aish!"
                                secondary="Desafío no encontrado 😔"
                                small
                            />
                            :
                            <></>
                    )

            }
        </Page>
    );
}

export default View;