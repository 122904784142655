/*
 * CHALLENGES.
 */

// Types.
export const typeNormal = 0;
export const typeDiagnostic = 1;

export const typeToText = (type) => {
    switch (type) {
        case typeNormal:
            return "Normal";

        case typeDiagnostic:
            return "Diagnóstico";

        default:
            return "";
    }
}

// Areas.
export const areaLanguage = 0;
export const areaMaths = 1;

export const areaToText = (area) => {
    switch (area) {
        case areaLanguage:
            return "Lenguaje";

        case areaMaths:
            return "Matemáticas";

        default:
            return "";
    }
}

// Base Text Type.
export const baseTextTypeUnassigned = 0;
export const baseTextTypeLiterary = 1;
export const baseTextTypeNonLiterary = 2;
export const baseTextTypeMassiveMeansOfCommunication = 3;

export const baseTextTypeToText = (type) => {
    switch (type) {
        case baseTextTypeUnassigned:
            return "Sin asignar";

        case baseTextTypeLiterary:
            return "Literario";

        case baseTextTypeNonLiterary:
            return "No literario";

        case baseTextTypeMassiveMeansOfCommunication:
            return "Medios masivos de comunicación";

        default:
            return "";
    }
}

// Themes.
export const themeLanguage = {
    0: "Reconocer e Identificar",
    1: "Relacionar e Interpretar",
    2: "Evaluar y Reflexionar",
}

export const themeMaths = {
    0: "Números",
    1: "Álgebra y Funciones",
    2: "Geometría",
    3: "Probabilidad y Estadística",
}

export const themeToText = (area, theme) => {
    switch (area) {
        case areaLanguage:
            return themeLanguage[theme];

        case areaMaths:
            return themeMaths[theme];

        default:
            return "";
    }
}

// States.
export const stateInReview = 0;
export const stateAccepted = 1;
export const stateRejected = 2;

export const stateToText = (state) => {
    switch (state) {
        case stateInReview:
            return "En revisión";

        case stateAccepted:
            return "Aceptado";

        case stateRejected:
            return "Rechazado";

        default:
            return "";
    }
}

// Default Values.
// Alternatives.
export const defaultQtyOfAlternatives = 4;

export const defaultAlternative = {
    "answer": "",
    "is_correct": false,
};

// Questions.
export const defaultQuestion = {
    "theme": 0,
    "question": "",
    "image": {
        "url": "",
    },
    "alternatives": Array(defaultQtyOfAlternatives).fill(defaultAlternative),
    "solution": "",
    "difficulty_initial": 1,
    "difficulty_calculated": 0,
    "complexity": 1,
    "annotation": "",
};

// Challenge
export const defaultChallenge = {
    "type": 0,
    "area": 0,
    "base_text_type": 0,
    "base_text": "",
    "base_image": {
        "url": "",
    },
    "source": "",
    "questions": [defaultQuestion,],
};