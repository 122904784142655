import React, {useContext, useEffect, useState} from "react";

import {Outlet, useNavigate} from "react-router-dom";

// Components.
import PersonalizedMessage from "../PersonalizedMessage/PersonalizedMessage";

// Contexts.
import {AppContext} from "../../contexts/AppContext";
import {UserContext} from "../../contexts/UserContext";

// Models.
import {roleAdministration, roleSpecialist} from "../../models/users";

// Utils.
import {apiAuthInstance} from "../../utils/api";
import {getSessionTokens, saveSessionTokens} from "../../utils/session_tokens";
import {killSession} from "../../utils/kill_session";

function PrivateRoute() {

    // Contexts.
    const appContext = useContext(AppContext);
    const userContext = useContext(UserContext);

    // Navigate method.
    const navigate = useNavigate();

    // States.
    const [retry, setRetry] = useState(0);

    // Error states.
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // Validate user session.
    useEffect(() => {

        if (!appContext.value.initialized) {

            // Set loading.
            appContext.setValue(prevState => ({
                ...prevState,
                loading: true,
            }));

            // Clean errors.
            setError(false);
            setErrorMessage("");

            // Load user tokens.
            let tokens = getSessionTokens();

            // Check if exist cookie with refresh token.
            // If existed, validate.
            // If not exist, kill session and redirect.
            if (tokens.refreshToken) {

                apiAuthInstance.post("/revalidate_session", {
                    "refresh_token": tokens.refreshToken
                }).then((response) => {

                    // Check if is valid.
                    if (response.data.auth.valid_session) {

                        // Session is valid!
                        // Now, check roles.
                        if (response.data?.user?.role && [roleSpecialist, roleAdministration].includes(response.data.user.role)) {

                            // Save new tokens.
                            saveSessionTokens(response.data.auth.access_token, tokens.refreshToken);

                            // Update user data.
                            userContext.setValue(prevState => ({
                                ...prevState,
                                ...response.data.user,
                            }));

                            // Set initialized and disable loading.
                            appContext.setValue(prevState => ({
                                ...prevState,
                                initialized: true,
                                loading: false,
                            }));

                        } else {
                            // Role is not valid.

                            // Disable loading.
                            appContext.setValue(prevState => ({
                                ...prevState,
                                loading: false,
                            }));

                            // Redirect.
                            navigate("/unauthorized");
                        }

                    } else {
                        // Session is not valid or server error.
                        // Sign out.
                        killSession().then(()=> {
                            // Redirect.
                            window.location.replace(process.env.REACT_APP_HOST_ACCOUNTS + "/login?redirect=" + process.env.REACT_APP_HOST_SPECIALISTS);
                        });
                    }

                }).catch((error) => {
                    // TODO: Handler all errors.
                    // Session is not valid or server is down.

                    // Disable loading.
                    appContext.setValue(prevState => ({
                        ...prevState,
                        loading: false,
                    }));

                    // If server response is unauthorized.
                    if (error.response?.status === 401) {
                        // Sign out.
                        killSession().then(()=> {
                            // Redirect.
                            window.location.replace(process.env.REACT_APP_HOST_ACCOUNTS + "/login?redirect=" + process.env.REACT_APP_HOST_SPECIALISTS);
                        });
                    }

                    // If server is down or user has no connection.
                    if (error.code === "ECONNABORTED" || error.code === "ERR_NETWORK") {
                        // Set error.
                        setError(true);
                        setErrorMessage("¡Sin conexión con el servidor! 😱");
                    }

                });
            } else {
                // No exist session.
                // Sign out.
                killSession().then(()=> {
                    // Redirect.
                    window.location.replace(process.env.REACT_APP_HOST_ACCOUNTS + "/login?redirect=" + process.env.REACT_APP_HOST_SPECIALISTS);
                });
            }
        }
    }, [retry]);

    return (
        appContext.value.initialized ?
            <Outlet/>
            :
            (
                error ?
                    <PersonalizedMessage
                        title="Aish!"
                        secondary={errorMessage}
                        actionText="Reintentar"
                        actionOnClick={_ => setRetry(prevState => prevState + 1)}
                    />
                    :
                    <></>
            )
    );
}

export default PrivateRoute;